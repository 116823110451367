const Width = {
  mobile_small: "320px",
  mobile_medium: "376px",
  mobile_large: "480px",
  about_small: "550px",
  about_medium: "1320px",
  tablet_small: "600px",
  tablet: "800px",
  laptop_small: "1024px",
  laptop_medium: "1200px",
  laptop_large: "1440px",
  project_medium: "960px"
};

const Height = {
  home_minimun: "640px"
}

const Breakpoints = {
  Width: Width,
  Height: Height
}

export default Breakpoints;