// --------------------------------------------------
// Basic Colors
// --------------------------------------------------

const Basic = {
  black: "#000",
  white: "#fff",
  gray_21: "#363636",
  gray_31: "#505050",
  gray_50: "#808080",
  gray_63: "#a0a0a0",
  gray_75: "#c0c0c0",
  gray_80: "#cccccc",
  gray_88: "#e0e0e0",
  gray_92: "#ebebeb",
  gray_94: "#efefef",
  gray_95: "#f2f2f2",
  gray_98: "#fafafa",
  gray_blue_01: "#363A3D",
  gray_blue_02: "#2E3033",
  orange_01: "#EA9C00",
  blue_01: "#1A5A90",
  blue_02: "43A9FE",
  light_blue_gray: "#EEEFEF"
}

const Text = {
  default: Basic.gray_31,
  emphasized: Basic.gray_21,
  light: Basic.gray_75,
  paragraph: Basic.gray_31,
  link: Basic.blue_01,
  section_title: Basic.gray_31,
  nav_item: Basic.gray_50,
  nav_item_hover: Basic.blue_01,
  nav_item_active: Basic.gray_blue_01,
  mobile_nav_item: Basic.white,
  mobile_nav_item_hover: Basic.orange_01,
  home_card_title: Basic.white,
  home_card_subtitle: Basic.gray_63,
  home_card_description: Basic.gray_80,
  home_scrolldown_text: Basic.gray_blue_01,
  about_button_hover: Basic.blue_01,
  about_light_button_hover: Basic.orange_01,
  portfolio_project_title: Basic.gray_21,
  portfolio_project_description: Basic.gray_31,
  contact_section_title: Basic.white,
  contact_subtitle: Basic.gray_94,
  contact_method_title: Basic.gray_75,
  contact_method_title_hover: Basic.white,
  contact_method_value: Basic.white,
  contact_method_value_hover: Basic.orange_01,
  copyright: Basic.gray_75,
  project_current: Basic.gray_21,
  project_meta_title: Basic.gray_50
}

const Background = {
  main_nav: Basic.white,
  main_nav_on_top: Basic.light_blue_gray,
  home: Basic.light_blue_gray,
  home_card: Basic.gray_blue_01,
  about: Basic.white,
  portfolio: Basic.gray_98,
  contact: Basic.gray_blue_01,
  mobile_nav: Basic.gray_blue_01,
  section_indicator_text_container: "rgba(0, 0, 0, 0.35)",
  section_indicator_hover: Basic.blue_01,
  footer: Basic.gray_blue_02
}

const Separator = {
  home_card: "rgba(238, 239, 239, 0.2)",
  about_skillset_top: "rgba(0, 0, 0, 0.05)",
  about_skillset: "rgba(0, 0, 0, 0.1)",
  portfolio_title: "rgba(0, 0, 0, 0.1)",
  project_title: "rgba(0, 0, 0, 0.2)",
  project_title_light: "rgba(255, 255, 255, 0.2)",
  project_footer: "rgba(0, 0, 0, 0.02)",
  contact: "rgba(255, 255, 255, 0.3)"
}

const Icon = {
  default: Basic.gray_31,
  default_hover: Basic.orange_01,
  white: Basic.white,
  white_hover: Basic.orange_01,
}

const Underline = {
  blue: "#5EA4EB",
  pink: "#EB5E8D",
  green: "#6BB347",
  purple: "#C475EB",
  teal: "#60BFAF",
  yellow: "#EBAC2F"
}

const Colors = {
  Basic: Basic,
  Text: Text,
  Background: Background,
  Separator: Separator,
  Icon: Icon,
  Underline: Underline
}

export default Colors;