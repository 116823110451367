const Font_family = {
  sans_serif: 'Roboto, "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif',
  sans_serif_condensed: '"Roboto Condensed", Roboto, "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif',
  title_condensed: '"Bebas Neue Regular","Bebas Neue", "Roboto Condensed", Roboto, "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif',
  monospace: '"Roboto Mono", monospace',
};

const Fonts = {
  Font_family: Font_family
};

export default Fonts;